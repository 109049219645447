@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: black;
  max-width: 1440px;
  margin: auto;
}

.projecttab{
  display: flex;
  
}

.projecttab >button {  
  margin-right: 30px;
}

.App {
  font-family: sans-serif;
  text-align: center;
  width: 1046px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
  gap: 22px;
}

.container-header {
  background-color: white;
  display: flex;
  border-radius: 12px;
  
}

.container-header > img {
  margin: 30px;
  width: 23.333333%;
  height: auto;
  border-radius: 12px;
  
}

.header-content {
  margin: 30px;
  width: 100%;
}

.header-title {
  display: flex;
  justify-content: space-between;
}

.header-title > div {
  text-align: left;
}

.header-title > div > h1 {
  margin: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #4f4f4f;
}

.header-title > div > p {
  margin: 10px 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #828282;
}

.header-body {
  margin: 30px 0;
  width: 80%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.header-body > p {
  margin: 10px 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #828282;
}

/* --------------------------body ------------------------------ */

.container-body {
  display: flex;
  grid-gap: 22px;
  gap: 22px;
}

.body-one {
  width: 40%;
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
  gap: 22px;
}
.container-body h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #4f4f4f;
  text-align: left;
}

.container-body p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  margin: 5px 0;
  text-align: left;
}

.social-div{
  padding-top: 20px;
  display: flex;
  justify-content: space-between;

  
}

.fab{
  color: black;
}

.tech {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
}

.tech > table {
  width: 100%;
  margin: 20px auto;
  text-align: left;
}

.hobbies {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  text-align: left;
}

.hobbies > div > img {
  width: 100%;
  border-radius: 12px;
}

.hobbies > div {
  margin: 20px 0;
}

.hobbies h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  margin: 10px 0;
}

.hobbies p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  margin: 5px 0;
}

.body-two {
  width: 60%;
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
  gap: 22px;
}

.blog {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
}

.slider-content {
  background-color: white;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  text-align: left;
}

.slider-content img {
  width: 90%;
  margin: 20px 0;
  border-radius: 12px;
}

.slider-content button {
  width: 20%;
  height: auto;
  background: none;
  border-radius: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #494545;
  margin: 5px 0;
  outline: none;
}

.slider-content button:active {
  position: relative;
  top: 5px;
}

.slider-content h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  margin: 10px 0;
}

.slider-content p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  margin: 5px 0;
  padding: 20px 0;
}

.slider {
  margin: 10px;
  padding: 24px;
}

.slick-prev {
  left: 0% !important;
  z-index: 1;
  background-color: rgb(15, 15, 15);
  border-radius: 70%;
}
.slick-next {
  right: 0% !important;
  z-index: 1;
  background-color: rgb(15, 15, 15);
  border-radius: 70%;
}

.slick-prev:hover {
  background-color: rgb(15, 15, 15);
  border-radius: 70%;
}

.slick-next:hover {
  background-color: rgb(15, 15, 15);
  border-radius: 70%;
}

.exp {
  background-color: white;
  border-radius: 12px;
  padding: 24px 10px;
}

.exp-container{
display:flex;
margin: 10px;
justify-content: space-between;


}

.exp-container img{
  width: 30%;
  height: 30%;
  padding-top: 5%
  
  }

  .exp-content{
    margin: 0 10px;   
    text-align: justify;
    
  }

  .exp-content p{
   
    font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
  margin: 5px 0;
  

  }

  .exp-content h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #4f4f4f;
  
  }

.btn-one{
  
  
  background: none;
  border-radius: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin: 5px 0;
  outline: none;
}

.pro-img{
  border: 2px solid black;
  border-radius: 12px
}

.project-content{
margin-bottom: 50px;
}

.btn-two{
  
  
  background: black;
  border-radius: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: white;
  margin: 5px 0;
  outline: none;
}

@media screen and (max-width:800px){
.App{
  width: 100%;
}

  .container-header{
    width: 70%;
    margin: auto;
    flex-direction:column;
    max-width: 100%;
    
  }

  .container-header>img{
    width: 70%;
    margin: 30px auto;
    
  }

  .header-content{
    width: 70%;
    margin: 30px auto;
    
    
  }

  .header-title{
    width: 80%;
    max-width: 100%; 
    margin: auto;
    flex-direction:column;
    justify-content: center;
   
  }
  .header-title>div>p{
    font-size: 14px;
    max-width: 100%; 
    
   
  }

  

  .header-body{
    width: 80%;
    margin: 30px auto;
    text-align: left;
    
    
  }

  .header-body>p{
    font-size: 14px;
  }

  .slider-content button {
   width: 40%;
  }

  .container-body{
    width: 100%;
    margin: 30px auto;
    flex-direction:column;
    max-width: 100%;
    
  }

  .container-body p {
    font-size: 14px;
  }

  

  .body-one{
    width: 70%;
    margin: auto;
    grid-gap: 40px;
    gap: 40px;
  }

  .body-two{
    width: 70%;
    margin: auto;
    
  }

.tech{
  margin-bottom: 30px;

}

.exp{
  margin-bottom: 30px;

}

.hobbies{
  margin-bottom: 30px;

}

.hobbies p {
  font-size: 14px;
}

.blog{
  margin-bottom: 30px;

}

.projects{
  margin-bottom: 30px;

}
  
}
